import {
  bulkAddUsersToGroups,
  bulkDeleteGroups,
  createGroup,
  deleteGroup,
  updateGroup
} from '@/api/group';

import { t } from '@/i18next';

import {
  BULK_ADD_TO_GROUPS_MODAL,
  BULK_DELETE_GROUPS_MODAL,
  CREATE_EDIT_GROUP_MODAL,
  DELETE_ENTITY_MODAL,
  VIEW_GROUP_MODAL
} from '@/constants/modals';

import { GROUPS_API } from '@/constants/api';

import type {
  BulkAddUsersToGroupParams,
  BulkDeleteGroupsParams,
  CreateGroupParams,
  UpdateGroupParams
} from '@/api/group/types';

import type { ListGroup } from '@/types/groups';
import type { PromiseResolve } from '@/types/helpers';
import type { UseModal } from '@/types/modals';

export function openCreateGroupModal(openModal: UseModal['openModal']) {
  async function createGroupHandler(
    resolve: PromiseResolve,
    groupParams: CreateGroupParams
  ) {
    const data = await createGroup(groupParams);

    resolve(data);
  }

  openModal<typeof CREATE_EDIT_GROUP_MODAL>(CREATE_EDIT_GROUP_MODAL, {
    confirmAction: createGroupHandler,
    useStatusConfirmation: true,
    invalidateQueryKey: GROUPS_API,
    successMessage: 'common.createdSuccessfully',
    allowGoBack: true
  });
}

export function openGroupDeleteModal(
  openModal: UseModal['openModal'],
  group: ListGroup
) {
  async function onDeleteGroupHandler(resolve: PromiseResolve) {
    const data = await deleteGroup(group.id);
    resolve(data);
  }

  openModal<typeof DELETE_ENTITY_MODAL>(DELETE_ENTITY_MODAL, {
    confirmAction: onDeleteGroupHandler,
    useStatusConfirmation: true,
    deselectItems: group.id,
    name: group.name,
    confirmTranslation: 'common.delete',
    modalMessage: 'manageGroup.delete_group',
    invalidateQueryKey: GROUPS_API,
    successMessage: 'common.deletedSuccessfully',
    successMessageParams: {
      entityType: t('manageGroups.group'),
      entityName: group.name
    }
  });
}

export function openGroupEditModal(
  openModal: UseModal['openModal'],
  group: ListGroup
) {
  async function onEditGroupHandler(
    resolve: PromiseResolve,
    groupParams: UpdateGroupParams
  ) {
    const data = await updateGroup(groupParams);
    resolve(data);
  }

  openModal<typeof CREATE_EDIT_GROUP_MODAL>(CREATE_EDIT_GROUP_MODAL, {
    confirmAction: onEditGroupHandler,
    useStatusConfirmation: true,
    allowGoBack: true,
    group: group,
    invalidateQueryKey: GROUPS_API,
    successMessage: 'common.updatedSuccessfully'
  });
}

export function openViewGroupModal(
  openModal: UseModal['openModal'],
  group: ListGroup
) {
  async function onEditGroupHandler(resolve: PromiseResolve) {
    openGroupEditModal(openModal, group);

    resolve(false);
  }

  openModal<typeof VIEW_GROUP_MODAL>(VIEW_GROUP_MODAL, {
    confirmAction: onEditGroupHandler,
    invalidateQueryKey: GROUPS_API,
    useStatusConfirmation: false,
    group
  });
}

export async function openBulkDeleteGroupsModal(
  openModal: UseModal['openModal']
) {
  async function bulkDeleteHandler(
    resolve: PromiseResolve,
    bulkDeleteParams: BulkDeleteGroupsParams
  ) {
    const data = await bulkDeleteGroups(bulkDeleteParams);
    resolve(data);
  }

  openModal<typeof BULK_DELETE_GROUPS_MODAL>(BULK_DELETE_GROUPS_MODAL, {
    confirmAction: bulkDeleteHandler,
    useStatusConfirmation: true,
    isBulkAction: true,
    deselectItems: true,
    invalidateQueryKey: GROUPS_API,
    successMessage: 'manageGroups.bulk_deletion_started'
  });
}

export async function openBulkAddToGroupsModal(
  openModal: UseModal['openModal']
) {
  async function bulkDeleteHandler(
    resolve: PromiseResolve,
    bulkUpdateParams: BulkAddUsersToGroupParams
  ) {
    const data = await bulkAddUsersToGroups(bulkUpdateParams);
    resolve(data);
  }

  openModal<typeof BULK_ADD_TO_GROUPS_MODAL>(BULK_ADD_TO_GROUPS_MODAL, {
    confirmAction: bulkDeleteHandler,
    useStatusConfirmation: true,
    allowGoBack: true,
    isBulkAction: true,
    invalidateQueryKey: GROUPS_API,
    successMessage: 'manageGroups.bulk_edit_started'
  });
}
